import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatButtonModule} from '@angular/material';
import {AssignProgressModalComponent} from './assign-progress-modal.component';
import {LoaderModule} from '../../../../../../../components/general/loader/loader.module';

@NgModule({
    declarations: [
        AssignProgressModalComponent
    ],
    imports: [
        CommonModule,
        LoaderModule,
        MatButtonModule,
    ],
    exports: [
        AssignProgressModalComponent
    ]
})
export class AssignProgressModalModule {
}
