import { Injectable } from '@angular/core';
import {ApiService} from '../api/api.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PendingReshufflesService {

  constructor(
      private api: ApiService
  ) {}

  public getScripts(params: any): Observable<any> {
    return this.api.get('v1/pending-assign', { params });
  }

  public getScript(id: number): Observable<any> {
    return this.api.get(`v1/pending-assign/${id}/info`, {});
  }

  public createScript(body: any): Observable<any> {
    return this.api.post('v1/pending-assign/create', { body });
  }

  public editScript(id: number, body: any): Observable<any> {
    return this.api.post(`v1/pending-assign/${id}/edit`, { body });
  }

  public deleteScript(body: any): Observable<any> {
    return this.api.delete('v1/pending-assign/delete', { body });
  }

  public cloneScript(id: number, body: any): Observable<any> {
    return this.api.post(`v1/pending-assign/${id}/clone`, {body});
  }

  public addSettings(id: number, body: any): Observable<any> {
    return this.api.post(`v1/pending-assign/${id}/settings`, {body});
  }

}
