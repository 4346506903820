import {Component, ElementRef, Inject, NgZone, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {takeUntil} from 'rxjs/operators';
import {CentrifugeService} from '../../../../../../../services/centrifuge/centrifuge.service';
import {Unsubscribe} from '../../../../../../../classes/unsubscribe';

@Component({
  selector: 'app-assign-progress-modal',
  templateUrl: './assign-progress-modal.component.html',
  styleUrls: ['./assign-progress-modal.component.less']
})
export class AssignProgressModalComponent extends Unsubscribe implements OnInit {

  @ViewChild('list') list: ElementRef;

  public progressList = [];

  public isProcessing = false;

  constructor(
      @Inject(MAT_DIALOG_DATA) public data: any,
      public self: MatDialogRef<AssignProgressModalComponent>,
      private centrifugeService: CentrifugeService,
      private ngZone: NgZone
  ) {
    super();
  }

  ngOnInit() {
    this.subscribeToCentrifugo(this.data.chanel);
  }

  private subscribeToCentrifugo(chanel: string): void {
    this.centrifugeService.listen(chanel).pipe(
        takeUntil(this.destroy)
    ).subscribe(({data}) => {
      this.ngZone.run(() => {
        this.progressList.push(data.message);

        this.isProcessing = data.status === 'Processing';

        setTimeout(() => {
          this.list.nativeElement.scrollTop  = this.list.nativeElement.scrollHeight;
        }, 0);
      });
    });
  }

}
